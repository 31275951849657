import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
};

export type CheckTwoFactorAuth = {
  __typename?: 'CheckTwoFactorAuth';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type CreateAppointment = {
  __typename?: 'CreateAppointment';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type CreateUser = {
  __typename?: 'CreateUser';
  success?: Maybe<Scalars['Boolean']>;
};


export type DefIds = {
  __typename?: 'DefIds';
  defId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  create?: Maybe<CreateAppointment>;
  checkTwoFactor?: Maybe<CheckTwoFactorAuth>;
  createUser?: Maybe<CreateUser>;
};


export type MutationCreateArgs = {
  caseNumber: Scalars['String'];
  defId: Scalars['String'];
  language: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  phoneNumber2?: Maybe<Scalars['String']>;
  phoneNumber3?: Maybe<Scalars['String']>;
};


export type MutationCheckTwoFactorArgs = {
  caseNumber: Scalars['String'];
  defId: Scalars['String'];
  pin: Scalars['Int'];
};


export type MutationCreateUserArgs = {
  additionalPhoneNumber: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum NotificationChannel {
  /** Sms */
  Sms = 'SMS'
}

export type NotificationType = Node & {
  __typename?: 'NotificationType';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  time: Scalars['DateTime'];
  message: Scalars['String'];
  channel: NotificationChannel;
  success?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  defIds?: Maybe<Array<Maybe<DefIds>>>;
  allNotifications?: Maybe<Array<Maybe<NotificationType>>>;
};


export type QueryDefIdsArgs = {
  caseNumber: Scalars['String'];
};

export type AllNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllNotificationsQuery = { __typename?: 'Query', allNotifications?: Maybe<Array<Maybe<{ __typename?: 'NotificationType', id: string, message: string, time: any, success?: Maybe<boolean>, channel: NotificationChannel }>>> };

export type CreateUserMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
  additionalPhoneNumber: Scalars['String'];
  date: Scalars['String'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: Maybe<{ __typename?: 'CreateUser', success?: Maybe<boolean> }> };

export type CreateAppointmentMutationVariables = Exact<{
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  phoneNumber2?: Maybe<Scalars['String']>;
  phoneNumber3?: Maybe<Scalars['String']>;
  caseNumber: Scalars['String'];
  language: Scalars['String'];
  defId: Scalars['String'];
}>;


export type CreateAppointmentMutation = { __typename?: 'Mutation', create?: Maybe<{ __typename?: 'CreateAppointment', success?: Maybe<boolean>, message?: Maybe<string> }> };

export type CheckTwoFactorAuthMutationVariables = Exact<{
  caseNumber: Scalars['String'];
  defId: Scalars['String'];
  pin: Scalars['Int'];
}>;


export type CheckTwoFactorAuthMutation = { __typename?: 'Mutation', checkTwoFactor?: Maybe<{ __typename?: 'CheckTwoFactorAuth', success?: Maybe<boolean>, message?: Maybe<string> }> };

export type DefIdsQueryVariables = Exact<{
  caseNumber: Scalars['String'];
}>;


export type DefIdsQuery = { __typename?: 'Query', defIds?: Maybe<Array<Maybe<{ __typename?: 'DefIds', defId?: Maybe<string>, name?: Maybe<string> }>>> };


export const AllNotificationsDocument = gql`
    query AllNotifications {
  allNotifications {
    id
    message
    time
    success
    channel
  }
}
    `;

/**
 * __useAllNotificationsQuery__
 *
 * To run a query within a React component, call `useAllNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<AllNotificationsQuery, AllNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllNotificationsQuery, AllNotificationsQueryVariables>(AllNotificationsDocument, options);
      }
export function useAllNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllNotificationsQuery, AllNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllNotificationsQuery, AllNotificationsQueryVariables>(AllNotificationsDocument, options);
        }
export type AllNotificationsQueryHookResult = ReturnType<typeof useAllNotificationsQuery>;
export type AllNotificationsLazyQueryHookResult = ReturnType<typeof useAllNotificationsLazyQuery>;
export type AllNotificationsQueryResult = Apollo.QueryResult<AllNotificationsQuery, AllNotificationsQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($phoneNumber: String!, $additionalPhoneNumber: String!, $date: String!) {
  createUser(phoneNumber: $phoneNumber, additionalPhoneNumber: $additionalPhoneNumber, date: $date) {
    success
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      additionalPhoneNumber: // value for 'additionalPhoneNumber'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateAppointmentDocument = gql`
    mutation CreateAppointment($name: String!, $phoneNumber: String!, $phoneNumber2: String, $phoneNumber3: String, $caseNumber: String!, $language: String!, $defId: String!) {
  create(name: $name, phoneNumber: $phoneNumber, phoneNumber2: $phoneNumber2, phoneNumber3: $phoneNumber3, caseNumber: $caseNumber, language: $language, defId: $defId) {
    success
    message
  }
}
    `;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<CreateAppointmentMutation, CreateAppointmentMutationVariables>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      phoneNumber2: // value for 'phoneNumber2'
 *      phoneNumber3: // value for 'phoneNumber3'
 *      caseNumber: // value for 'caseNumber'
 *      language: // value for 'language'
 *      defId: // value for 'defId'
 *   },
 * });
 */
export function useCreateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppointmentMutation, CreateAppointmentMutationVariables>(CreateAppointmentDocument, options);
      }
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>;
export const CheckTwoFactorAuthDocument = gql`
    mutation CheckTwoFactorAuth($caseNumber: String!, $defId: String!, $pin: Int!) {
  checkTwoFactor(caseNumber: $caseNumber, defId: $defId, pin: $pin) {
    success
    message
  }
}
    `;
export type CheckTwoFactorAuthMutationFn = Apollo.MutationFunction<CheckTwoFactorAuthMutation, CheckTwoFactorAuthMutationVariables>;

/**
 * __useCheckTwoFactorAuthMutation__
 *
 * To run a mutation, you first call `useCheckTwoFactorAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckTwoFactorAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkTwoFactorAuthMutation, { data, loading, error }] = useCheckTwoFactorAuthMutation({
 *   variables: {
 *      caseNumber: // value for 'caseNumber'
 *      defId: // value for 'defId'
 *      pin: // value for 'pin'
 *   },
 * });
 */
export function useCheckTwoFactorAuthMutation(baseOptions?: Apollo.MutationHookOptions<CheckTwoFactorAuthMutation, CheckTwoFactorAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckTwoFactorAuthMutation, CheckTwoFactorAuthMutationVariables>(CheckTwoFactorAuthDocument, options);
      }
export type CheckTwoFactorAuthMutationHookResult = ReturnType<typeof useCheckTwoFactorAuthMutation>;
export type CheckTwoFactorAuthMutationResult = Apollo.MutationResult<CheckTwoFactorAuthMutation>;
export type CheckTwoFactorAuthMutationOptions = Apollo.BaseMutationOptions<CheckTwoFactorAuthMutation, CheckTwoFactorAuthMutationVariables>;
export const DefIdsDocument = gql`
    query DefIds($caseNumber: String!) {
  defIds(caseNumber: $caseNumber) {
    defId
    name
  }
}
    `;

/**
 * __useDefIdsQuery__
 *
 * To run a query within a React component, call `useDefIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefIdsQuery({
 *   variables: {
 *      caseNumber: // value for 'caseNumber'
 *   },
 * });
 */
export function useDefIdsQuery(baseOptions: Apollo.QueryHookOptions<DefIdsQuery, DefIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefIdsQuery, DefIdsQueryVariables>(DefIdsDocument, options);
      }
export function useDefIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefIdsQuery, DefIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefIdsQuery, DefIdsQueryVariables>(DefIdsDocument, options);
        }
export type DefIdsQueryHookResult = ReturnType<typeof useDefIdsQuery>;
export type DefIdsLazyQueryHookResult = ReturnType<typeof useDefIdsLazyQuery>;
export type DefIdsQueryResult = Apollo.QueryResult<DefIdsQuery, DefIdsQueryVariables>;